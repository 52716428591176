import React, { Component } from 'react';
import {HorizontalBar} from 'react-chartjs-2';
import ProgramActions from '../../models/program_actions.js';

class ExerciseEfficacyChart extends Component {
  render() {
    if (this.props.data === null) {
      return null;
    }

    var improvedData = [];
    var notImprovedData = [];
    var labels = [];

    var dataAsArray = Object.entries(this.props.data);
    dataAsArray.sort( (a, b) => {
      if (a[1]['better'] > b[1]['better']) {
        return -1;
      }
      else if (a[1]['better'] === b[1]['better']) {
        if (a[1]['notBetter'] > b[1]['notBetter']) {
          return -1;
        }
        else {
          return 1;
        }
      }
      else {
        return 1;
      }
    });
    dataAsArray.forEach((item, i) => {
      improvedData.push(item[1]['better']);
      notImprovedData.push(item[1]['notBetter']);
      labels.push(ProgramActions[item[0]]['humanReadable']);
    });

    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Improved',
          backgroundColor: '#44FF0099',
          borderColor: '#44FF00',
          borderWidth: 1,
          data: improvedData
        },
        {
          label: 'No Improvement',
          backgroundColor: '#FF6384cc',
          borderColor: '#FF6384',
          borderWidth: 1,
          data: notImprovedData,
        }
      ]
    };
    const options = {
      scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            fontColor: '#ffffff',
            precision: 0,
          },
          gridLines: {
            drawOnChartArea: false,
            color: '#ffffff'
          },
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            fontColor: '#ffffff',
            precision: 0,
          },
          gridLines: {
            drawOnChartArea: false,
            color: '#ffffff'
          },
        }]
      },
      legend: {
        labels: {
          fontColor: "#ffffff"
        }
      }
    };

    return (
      <div>
        <HorizontalBar data={data} options={options} />
      </div>
    );
  }
}

export default ExerciseEfficacyChart;
