import React, { Component } from 'react';

class SkipLink extends Component {
  render() {
    return(
      <div>
        <label className="skip-link" onClick={this.props.handleSkip}>
          SKIP
        </label>
      </div>
    );
  }
}

export default SkipLink;
