import React, { Component } from 'react';
import StatusCircle from '../components/StatusCircles/StatusCircle.js';
import StatusStringMap from '../models/status_string_map.js';

class CurrentStatusArea extends Component {
  render() {
    const bodyText = `Feeling ${StatusStringMap[this.props.howFeelingPhysicallyInitial]}`;
    const mindText = `Feeling ${StatusStringMap[this.props.howFeelingMentallyInitial]}`;

    var improvedTextArea = null;
    if (this.props.improvedText != null) {
      improvedTextArea = (<p className="conclusion-improvement-text">{this.props.improvedText}</p>);
    }

    if (this.props.skipped) {
      return null;
    }
    else {
      return (
        <div>
          <div className="conclusion-header-area flex-container">
            <div className="fade-in flexible-width conclusion-box conclusion-activity">
              <div>
                <p className="conclusion-date">CURRENT FEELING</p>
                <p className="conclusion-header">{this.props.subFeeling1}</p>
                {improvedTextArea}
              </div>
              <hr className="status-divider" />
              <div className="flex-container status-container">
                <div>
                  <p className="status-label">Mind</p>
                  <StatusCircle
                    initialValue={this.props.howFeelingMentallyInitial}
                    />
                  <p className="status-description">
                    {mindText}
                  </p>
                </div>
                <div>
                  <p className="status-label">Body</p>
                  <StatusCircle
                    initialValue={this.props.howFeelingPhysicallyInitial}
                    />
                  <p className="status-description">
                    {bodyText}
                  </p>
                </div>
              </div>
              <div>
                {this.props.trackButton}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default CurrentStatusArea;
