import React, { Component } from 'react';

class CurrentEmotionSelector extends Component {
  render() {
    const optionsBlock = this.props.emotions.map( (item, key) =>
      <button key={key} className="SelectionButton SelectorButton" type="button" value={item} onClick={this.props.selectEmotion}>{item}</button>
    );

    return (
      <div>
        <div className="flex-container fade-in flexible-width">
          {optionsBlock}
        </div>
      </div>
    );
  }
}

export default CurrentEmotionSelector;
