import React, { Component } from 'react';

class StatusSelector extends Component {
  handleValueSelection = (e) => {
    const value = parseInt(e.target.value);
    this.props.handleValueSelection(value);
  }

  render() {
    return (
      <div className="flex-container fade-in">
        <button className="SelectionButton SelectorButton" type="button" value="1" onClick={this.handleValueSelection}>Terrible</button>
        <button className="SelectionButton SelectorButton" type="button" value="2" onClick={this.handleValueSelection}>Very Bad</button>
        <button className="SelectionButton SelectorButton" type="button" value="3" onClick={this.handleValueSelection}>Bad</button>
        <button className="SelectionButton SelectorButton" type="button" value="4" onClick={this.handleValueSelection}>Okay</button>
        <button className="SelectionButton SelectorButton" type="button" value="5" onClick={this.handleValueSelection}>Good</button>
        <button className="SelectionButton SelectorButton" type="button" value="6" onClick={this.handleValueSelection}>Great</button>
        <button className="SelectionButton SelectorButton" type="button" value="7" onClick={this.handleValueSelection}>Amazing</button>
      </div>
    );
  }
}

export default StatusSelector;
