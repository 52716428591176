import React, { Component } from 'react';
import CurrentEmotionSelector from '../components/elements/CurrentEmotionSelector.js';
import SkipLink from '../components/elements/SkipLink.js';

const HAPPY_KEY = 'Happy';
const MEH_KEY = 'Meh';
const BAD_KEY = 'Bad';

var EMOTION_LOOKUP = {};
EMOTION_LOOKUP[HAPPY_KEY] = [
  'Accepted', 'Content', 'Excited', 'Joyful', 'Optimistic', 'Peaceful', 'Playful', 'Powerful', 'Proud', 'Relieved'
];
EMOTION_LOOKUP[MEH_KEY] = [
  'Anxious', 'Bored', 'Confused', 'Distracted', 'Insecure', 'Disappointed', 'Scared', 'Stressed', 'Tired', 'Numb'
];
EMOTION_LOOKUP[BAD_KEY] = [
  'Angry', 'Awful', 'Bitter', 'Depressed', 'Despairing', 'Frustrated', 'Guilty', 'Humiliated', 'Hurt', 'Lonely'
];

class CurrentFeelingStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBaseFeeling: null,
      emotions: []
    };
  }

  selectEmotion = (e) => {
    const value = e.target.value;
    this.props.setSubFeeling1(value);
    this.props.setBaseFeeling(this.state.selectedBaseFeeling);

    this.props.advanceWizard()
  }

  handleBaseFeelingClick = (e) => {
    const value = e.target.value
    this.setState({
      selectedBaseFeeling: value,
      emotions: EMOTION_LOOKUP[value]
    });
  }

  render() {
    var divider = null;
    if (this.state.emotions.length > 0) {
      divider = <hr className="current-feeling-divider" />;
    }
    return(
      <header className="App-header">
        <div className="fade-in">
          <div>
            <p>What is your current mood?</p>
            <div className="fade-in">
              <button className={`SelectionButton SelectorButton ${this.state.selectedBaseFeeling === HAPPY_KEY ? 'selected' : null}`} type="button" value={HAPPY_KEY} onClick={this.handleBaseFeelingClick}>Happy</button>
              <button className={`SelectionButton SelectorButton ${this.state.selectedBaseFeeling === MEH_KEY ? 'selected' : null}`} type="button" value={MEH_KEY} onClick={this.handleBaseFeelingClick}>Meh</button>
              <button className={`SelectionButton SelectorButton ${this.state.selectedBaseFeeling === BAD_KEY ? 'selected' : null}`} type="button" value={BAD_KEY} onClick={this.handleBaseFeelingClick}>Bad</button>
            </div>
          </div>
          {divider}
          <CurrentEmotionSelector
            selectEmotion={this.selectEmotion}
            emotions={this.state.emotions}
          />
        </div>
        <SkipLink handleSkip={this.props.handleSkip} />
      </header>
    );
  }
}

export default CurrentFeelingStep;
