import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';

class ResetButton extends Component {
  render() {
    if (this.props.showReset) {
      return (
        <FontAwesomeIcon className="reset-button" icon={faUndo} onClick={this.props.doReset} />
      );
    }
    else {
      return null;
    }
  }
}

export default ResetButton;
