import React, { Component } from 'react';
import {Doughnut} from 'react-chartjs-2';

const COLOR_LOOKUP = {
  0: "#ff0000",
  1: "#FF4300",
  2: "#FF8700",
  3: "#FFCC00",
  4: "#B9FF00",
  5: "#69FF00",
  6: "#53FF00",
  7: "#44FF00",
};
const TOTAL = 7;

// TODO refactor this to remove the interim class
class StatusCircle extends Component {
  render() {
    const initialValue = parseInt(this.props.initialValue);
    const baseColor = COLOR_LOOKUP[initialValue];

    const data = {
      datasets: [{
        data: [initialValue, TOTAL - initialValue],
        backgroundColor: [baseColor, "#00000000"],
        borderWidth: 1,
        borderColor: [baseColor]
      }],
      labels: ["Before", "After"],
    };
    const options = {
      legend: {
        display: false
      }
    };

    return (
      <div>
        <Doughnut data={data} options={options} />
      </div>
    );
  }
}

export default StatusCircle;
