import React, { Component } from 'react';
import ProgramActions from '../models/program_actions.js';

class SelectProgramStep extends Component {
  selectBreath = () => {
    this.props.selectNextStep(ProgramActions.breath_step.key);
  }

  selectWalk = () => {
    this.props.selectNextStep(ProgramActions.walk_step.key);
  }

  selectTalk = () => {
    this.props.selectNextStep(ProgramActions.talk_step.key);
  }

  selectBodyScan = () => {
    this.props.selectNextStep(ProgramActions.body_scan_step.key);
  }

  selectSenseScan = () => {
    this.props.selectNextStep(ProgramActions.sense_step.key);
  }

  render() {
    return (
      <header className="App-header">
        <div className="fade-in">
          <p className="select-program-explanation">Select an exercise to center yourself</p>
          <div className="select-program-buttons">
            <button className="SelectionButton" type="button" onClick={this.selectBreath}>
              Breathing
            </button>
            <button className="SelectionButton" type="button" onClick={this.selectBodyScan}>
              Body Scan
            </button>
            <button className="SelectionButton" type="button" onClick={this.selectSenseScan}>
              Sense Check
            </button>
            <button className="SelectionButton" type="button" onClick={this.selectTalk}>
              Typing
            </button>
            <button className="SelectionButton" type="button" onClick={this.selectWalk}>
              Walking
            </button>
          </div>
        </div>
      </header>
    );
  }
}

export default SelectProgramStep;
