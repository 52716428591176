import React, { Component } from 'react';

class TalkStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textValue: ""
    };
  }

  handleTalkInputChange = (event) => {
    var val = event.target.value;
    this.setState({
      textValue: val
    });
    this.props.setTalkOutput(val);
  }

  handleNextButtonClick = (event) => {
    var val = this.state.textValue;
    if (val.length < 1) {
      alert("Type out what's on your mind.");
    }
    else {
      this.props.advanceWizard();
    }
  }

  render() {
    return(
      <header className="App-header">
        <div className="fade-in">
          <p className="small-text flexible-width">Type your thoughts...</p>
          <textarea autoFocus className="talk-it-out-textarea" value={this.state.textValue} onChange={this.handleTalkInputChange}>
          </textarea>
          <div>
            <button className="SelectionButton" type="button" onClick={this.handleNextButtonClick}>
              Next
            </button>
          </div>
        </div>
      </header>
    );
  }
}

export default TalkStep;
