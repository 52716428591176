var ProgramActions = {
  breath_step: {
    key: 'breath_step',
    humanReadable: 'Breathing',
    description: 'focused breathing'
  },
  talk_step: {
    key: 'talk_step',
    humanReadable: 'Typing',
    description: 'typing out thoughts'
  },
  walk_step: {
    key: 'walk_step',
    humanReadable: 'Walking',
    description: 'taking a walk'
  },
  body_scan_step: {
    key: 'body_scan_step',
    humanReadable: 'Body Scan',
    description: 'performing body scan'
  },
  sense_step: {
    key: 'sense_step',
    humanReadable: 'Sense Check',
    description: 'scanning your senses'
  }
};

export default ProgramActions;
