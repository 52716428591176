import React, { Component } from 'react';
import {Pie} from 'react-chartjs-2';

const COLORS = [
  "#82e1f2",
  "#FF6384",
  "#FF9F40",
  "#FFC234",
  "#54cdb0",
  "#36A2EC",
  "#b070bf",
  "#656ced"
];
const NUM_COLORS = COLORS.length;

class MoodPieChart extends Component {
  render() {
    var moods = this.props.moods;
    var colors = [];
    var datapoints = [];
    var labels = [];
    var i = 0;
    for (const key of Object.keys(moods)) {
      labels.push(key);
      datapoints.push(moods[key]);
      colors.push(COLORS[(i % NUM_COLORS)]);
      i = i + 1;
    }

    const data = {
      datasets: [{
        data: datapoints,
        backgroundColor: colors,
        borderWidth: 1,
        borderColor: ["#addaad"]
      }],
      labels: labels,
    };
    const options = {
      legend: {
        labels: {
          fontColor: "#ffffff"
        }
      }
    };

    return (
      <div>
        <Pie data={data} options={options} />
      </div>
    );
  }
}

export default MoodPieChart;
