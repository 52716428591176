import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../components/Firebase';
import firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import ReactGA from 'react-ga';

const SigninPage = () => (
  <SigninForm />
);

if (process.env.NODE_ENV !== 'development') {
  ReactGA.initialize('UA-156984354-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

class SigninFormBase extends Component {
  constructor(props) {
    super(props);
    this.savedStateToBePersisted = null;
  }

  componentDidMount() {
    this.savedStateToBePersisted = this.props.location.state;

    var ui = new firebaseui.auth.AuthUI(firebase.auth());
    ui.start('#firebaseui-auth-container', {
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
      ],
      signInFlow: 'popup',
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          // If we're creating a new user, we need to also create their user doc
          // if (authResult.additionalUserInfo) {
          //   // Create a user in cloud firestore with first log
          //   console.log(authResult);
          //   const uid = authResult.user.uid;
          //
          //   this.props.firebase.db.collection('users').doc(authResult.user.uid).set({}).then( ref => {
          //     // TODO figure out what to do if there isn't state being passed in
          //     // And add logs as well
          //     var firstLogData = this.props.location.state;
          //
          //     this.props.firebase.db.collection('users').doc(uid).collection('logs').add(firstLogData).then( (result) => {
          //       // New user created, navigate now
          //       this.props.history.replace({
          //         pathname: '/',
          //         state: {
          //           savedStateToBePersisted: this.savedStateToBePersisted
          //         }
          //       });
          //     });
          //   });
          // }
          // else {

          // Existing user, navigate now
          console.log("Existing user, navigate now");
          this.props.history.replace({
            pathname: '/',
            state: {
              savedStateToBePersisted: this.savedStateToBePersisted
            }
          });

          // }
          return false;
        }
      },
    });
  }

  render() {
    return (
      <div className="login-page">
        <div className="login-area conclusion-box">
          <p className="login-text">Sign In to Serenity</p>
          <p className="explainer-text">
            We're under development!<br/><br/>
            Sign up below to track your progress over time, or <a href="https://serenity-app-signup.ck.page/21a4884a98">join our email list</a>&nbsp;to
            stay in touch. We&apos;ll only use your email to send very occasional product updates.
          </p>
          <div id="firebaseui-auth-container"></div>
        </div>
      </div>
    );
  }
}

const SigninForm = withRouter(withFirebase(SigninFormBase));

export default SigninPage;
export { SigninForm };
