const STATUS_TO_STRING_MAP = {
  1: "Terrible",
  2: "Really Bad",
  3: "Bad",
  4: "Okay",
  5: "Good",
  6: "Great",
  7: "Amazing"
};

export default STATUS_TO_STRING_MAP;
