import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../components/Firebase';

const INITIAL_STATE = {
  email: '',
  password: '',
  passwordConfirm: '',
  error: null,
};

const SignupPage = () => (
  <SignupForm />
);


class SignupFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  onSubmit = (event) => {
    const { email, password } = this.state;

    this.props.firebase.doCreateUserWithEmailAndPassword(email, password)
      .then( (authUser) => {
        // Create a user in cloud firestore
        return this.props.firebase.db.collection('users').doc(authUser.user.uid).set({});
      })
      .then( () => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push('/');
      })
      .catch(error => {
        console.log(error);
      });

    event.preventDefault();
  }

  render() {
    const {
      email,
      password,
      passwordConfirm,
      error,
    } = this.state;

    const isInvalid = (password !== passwordConfirm) || (password === '') || (email === '');

    return (
      <div className="signup-page">
        <form onSubmit={this.onSubmit}>
          <div>
            <input type="text" name="email" placeholder="Email" className="simple-input" value={email} onChange={this.onChange} />
          </div>
          <div>
            <input type="password" name="password" placeholder="Password" className="simple-input" value={password} onChange={this.onChange} />
          </div>
          <div>
            <input type="password" name="passwordConfirm" placeholder="Password Confirmation" className="simple-input" value={passwordConfirm} onChange={this.onChange} />
          </div>
          <button className="SelectionButton" type="submit" disabled={isInvalid} onClick={this.onSubmit}>
            Signup
          </button>
          {error && <p>{error.message}</p>}
        </form>
      </div>
    );
  }
}

const SignupForm = withRouter(withFirebase(SignupFormBase));

export default SignupPage;
export { SignupForm };
