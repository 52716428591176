import React, { Component } from 'react';
import { withFirebase } from '../components/Firebase';

import StatusBarChart from '../components/Charts/StatusBarChart.js';
import MoodPieChart from '../components/Charts/MoodPieChart.js';
import ExerciseEfficacyChart from '../components/Charts/ExerciseEfficacyChart.js';

import ProgramActions from '../models/program_actions.js';
import moment from 'moment';

import '../SerenityApp.scss';

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authUser: null,
      logs: [],
      mindTimeSeriesOutputData: [],
      bodyTimeSeriesData: [],
      moods: {},
      exerciseEfficacy: null
    };
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(
      authUser => {
        if (authUser) {
          this.setState({
            authUser: authUser
          });
          this.loadValues();
        }
      });
  }

  componentWillUnmount() {
    this.listener();
  }

  pad(n) {
    return ('00' + n).slice(-2);
  }

  toTime(s) {
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;

    return this.pad(hrs) + ':' + this.pad(mins) + ':' + this.pad(Math.round(secs));
  }

  loadValues = () => {
    if (this.state.authUser && navigator.onLine) {
      // 14 days ago
      const dateSinceTimestamp = Date.now() - (24*60*60*1000 * 14);

      // Load history
      var docRef = this.props.firebase.db.collection('users')
          .doc(this.state.authUser.uid)
          .collection('logs')
          .where('timestamp', '>', dateSinceTimestamp);
      docRef.get().then( (queryResult) => {
        if (queryResult.size > 0) {
          var countMoods = {};

          // Sort the logs by timestamp
          var result = queryResult.docs.map(doc => doc.data());

          // Iterate through the logs; generate date for the graph
          // and for other statistics to display
          var date = null;

          var mentalStatusesByDay = {};
          var physicalStatusesByDay = {};

          var exerciseEfficacy = {};
          exerciseEfficacy[ProgramActions['breath_step']['key']] = {better: 0, notBetter: 0};
          exerciseEfficacy[ProgramActions['talk_step']['key']] = {better: 0, notBetter: 0};
          exerciseEfficacy[ProgramActions['walk_step']['key']] = {better: 0, notBetter: 0};
          exerciseEfficacy[ProgramActions['body_scan_step']['key']] = {better: 0, notBetter: 0};
          exerciseEfficacy[ProgramActions['sense_step']['key']] = {better: 0, notBetter: 0};

          // Iterate through the logs
          result.forEach( (d) => {
            date = moment(d.timestamp).format("YYYY-MM-DD");

            // Handle the case of multiple entries on a given date by averaging them
            if (mentalStatusesByDay[date]) {
              mentalStatusesByDay[date]['count'] = mentalStatusesByDay[date]['count'] + 1;
              mentalStatusesByDay[date]['total'] = mentalStatusesByDay[date]['total'] + d.mental_initial;
            }
            else {
              mentalStatusesByDay[date] = {
                count: 1,
                total: d.mental_initial
              };
            }
            if (physicalStatusesByDay[date]) {
              physicalStatusesByDay[date]['count'] = physicalStatusesByDay[date]['count'] + 1;
              physicalStatusesByDay[date]['total'] = physicalStatusesByDay[date]['total'] + d.physical_initial;
            }
            else {
              physicalStatusesByDay[date] = {
                count: 1,
                total: d.physical_initial
              };
            }

            // Sum up the different moods
            if (!countMoods[d.current_sub_feeling]) {
              countMoods[d.current_sub_feeling] = 1;
            }
            else {
              countMoods[d.current_sub_feeling] = countMoods[d.current_sub_feeling] + 1;
            }

            // Calculate exercise efficacy
            var key = d.action;
            var exerciseResult = d.exercise_result;
            if (exerciseResult > 0) {
              let currentVal = exerciseEfficacy[key]['better'];
              exerciseEfficacy[key]['better'] = currentVal + 1;
            }
            else {
              let currentVal = exerciseEfficacy[key]['notBetter'];
              exerciseEfficacy[key]['notBetter'] = currentVal + 1;
            }
          });

          // Process averages for the status charts to handle duplicates
          var newMentalStatuses = [];
          for (const key of Object.keys(mentalStatusesByDay)) {
            newMentalStatuses.push({
              x: key,
              y: parseFloat(mentalStatusesByDay[key]['total']) / mentalStatusesByDay[key]['count']
            });
          }
          var newPhysicalStatuses = [];
          for (const key of Object.keys(physicalStatusesByDay)) {
            newPhysicalStatuses.push({
              x: key,
              y: parseFloat(physicalStatusesByDay[key]['total']) / physicalStatusesByDay[key]['count']
            });
          }

          this.setState({
            logs: result,
            mindTimeSeriesOutputData: newMentalStatuses,
            bodyTimeSeriesData: newPhysicalStatuses,
            moods: countMoods,
            exerciseEfficacy: exerciseEfficacy,
          });
        } else {
          console.log("No logs found!");
        }
      }).catch( (error) => {
        console.log("Error getting document:", error);
      });
    }
  }

  render() {
    var conclusionDetailsArea = null;

    // If there are logs to show past history
    if (this.state.logs.length > 0) {
      conclusionDetailsArea = (
        <div>
          <div className="flex-container">
            <div className="conclusion-status-holder conclusion-box flexible-width">
              <p className="conclusion-date">HISTORY</p>
              <StatusBarChart
                mindTimeSeriesData={this.state.mindTimeSeriesOutputData}
                bodyTimeSeriesData={this.state.bodyTimeSeriesData}
                redraw />
              <hr className="status-divider" />
              <p className="conclusion-date">Emotions</p>
              <MoodPieChart moods={this.state.moods} />
              <hr className="status-divider" />
              <p className="conclusion-date">Exercises</p>
              <ExerciseEfficacyChart data={this.state.exerciseEfficacy}/>
            </div>
          </div>
        </div>
      );
    }
    // If no logs, show an "advertisement" for the history view. Generate random
    // historical data.
    else {
      var dummyMindData = [];
      var dummyBodyData = [];
      const baseTimestamp = Date.now();
      const dateDiffMillis = 60*60*24*1000;

      // default starting values
      var ym = Math.round(2 + Math.random() * 4);
      var yb = Math.round(2 + Math.random() * 4);

      for (var i = 14; i > 0; i--) {
        var date = moment(baseTimestamp - (i * dateDiffMillis)).format("YYYY-MM-DD");

        // 70% of the time, maintain the same value as we just received
        if (Math.random() > 0.7) {
          // Average towards a positive slope. Keep in mind that this for loop iterates
          // from the present day backwards in time as it increments.
          if (i < 10) {
            ym = Math.round(6 + Math.random() * 1);
            yb = Math.round(6 + Math.random() * 1);
          }
          else {
            ym = Math.round(3 + Math.random() * 4);
            yb = Math.round(3 + Math.random() * 4);
          }
        }

        dummyMindData.push({
          x: date,
          y: ym,
        });
        dummyBodyData.push({
          x: date,
          y: yb,
        });
      }

      conclusionDetailsArea = (
        <div>
          <div className="flex-container">
            <div className="conclusion-status-holder conclusion-box flexible-width">
              <p className="conclusion-date">HISTORY (SAMPLE)</p>
              <StatusBarChart
                mindTimeSeriesData={dummyMindData}
                bodyTimeSeriesData={dummyBodyData}
                />
            </div>
          </div>
        </div>
      );
    }

    return(
      <header className="App-header">
        <div className="box-containing-step">
          {conclusionDetailsArea}
        </div>
      </header>
    );
  }
}

export default withFirebase(ProfilePage);
