import React, { Component } from 'react';

class BreathStep extends Component {
  keypressDetection = (event) => {
    // Enter
    if (event.keyCode === 13) {
      this.props.advanceWizard();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keypressDetection, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keypressDetection, false);
  }

  render() {
    return(
      <header className="App-header">
        <div className="fade-in">
          <p className="small-text">Inhale as the circle grows, exhale as it fades...</p>
          <div>
            <img className="breath-gif" alt="Breath in and out" src="https://i.imgur.com/NprwU9r.gif" />
          </div>
          <div>
            <button className="SelectionButton" type="button" onClick={this.props.advanceWizard}>
              Next
            </button>
          </div>
        </div>
      </header>
    );
  }
}

export default BreathStep;
