import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial#react-firebase-realtime-database-user-management

const config = {
  apiKey: "AIzaSyCjKUsWZm7xgsC1_RDZRggBbnzpTw2lhE4",
  authDomain: "serenity-7d5da.firebaseapp.com",
  databaseURL: "https://serenity-7d5da.firebaseio.com",
  projectId: "serenity-7d5da",
  storageBucket: "",
  messagingSenderId: "449441812446",
  appId: "1:449441812446:web:d1e26d6ab996d1c4"
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
  }

  doCreateUserWithEmailAndPassword = (email, password) => {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  doSignInWithEmailAndPassword = (email, password) => {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  doSignOut = () => {
    return this.auth.signOut();
  }

  doPasswordReset = email => {
    this.auth.sendPasswordResetEmail(email);
  }

  doPasswordUpdate = password => {
    this.auth.currentUser.updatePassword(password);
  }
}

export default Firebase;
