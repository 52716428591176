import React, { Component } from 'react';

class FeelingBetterStep extends Component {
  selectFeelingBetter = (event) => {
    const value = parseInt(event.target.value);
    this.props.setFeelingBetter(value);
    this.props.advanceWizard();
  }

  render() {
    return(
      <header className="App-header">
        <div className="fade-in">
          <p>
            Are you feeling better, worse, or the same?
          </p>
          <div className="select-program-buttons">
            <button className="SelectionButton" type="button" value="1" onClick={this.selectFeelingBetter}>
              Better
            </button>
            <button className="SelectionButton" type="button" value="0" onClick={this.selectFeelingBetter}>
              The Same
            </button>
            <button className="SelectionButton" type="button" value="-1" onClick={this.selectFeelingBetter}>
              Worse
            </button>
          </div>
        </div>
      </header>
    );
  }
}

export default FeelingBetterStep;
