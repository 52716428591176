import React, { Component } from 'react';
import { withFirebase } from '../components/Firebase';

import ProgramActions from '../models/program_actions.js';
import CurrentStatusArea from '../components/CurrentStatusArea.js';
import { Link, withRouter } from 'react-router-dom';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch, faClock, faChartBar } from '@fortawesome/free-solid-svg-icons';

class ConclusionStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      mindTimeSeriesOutputData: [],
      bodyTimeSeriesData: [],
      moods: {},
      isSaving: false,
      exerciseEfficacy: null
    };
    const durationSeconds = (Date.now() - this.props.startTime) / 1000.0;
    this.durationText = this.toTime(durationSeconds);
    this.startTimeText = moment(this.props.startTime).format('h:mm a');
  }

  componentDidMount() {
    this.loadValues();

    // For testing
    window.ktest = {
      getInfo: () => {
        console.log(this.props.authUser);
      }
    }
  }

  pad(n) {
    return ('00' + n).slice(-2);
  }

  toTime(s) {
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;

    return this.pad(hrs) + ':' + this.pad(mins) + ':' + this.pad(Math.round(secs));
  }

  getValues() {
    return {
      mental_initial: this.props.howFeelingMentallyInitial,
      physical_initial: this.props.howFeelingPhysicallyInitial,
      action: this.props.action,
      current_base_feeling: this.props.baseFeeling,
      current_sub_feeling: this.props.subFeeling1,
      timestamp: this.props.startTime,
      exercise_result: this.props.feelingBetter,
    };
  }

  saveValues = () => {
    this.setState({
      isSaving: true
    });

    var data = this.getValues();
    this.props.firebase.db.collection('users').doc(this.props.authUser.uid).collection('logs').add(data).then( (result) => {
      this.props.history.push("/profile");
    });
  }

  signout = () => {
    this.props.firebase.auth.signOut().then( () => {
      this.props.doReset();
    });
  }

  loadValues = () => {
    if (this.props.authUser && navigator.onLine) {
      // 14 days ago
      const dateSinceTimestamp = Date.now() - (24*60*60*1000 * 14);

      // Load history
      var docRef = this.props.firebase.db.collection('users')
          .doc(this.props.authUser.uid)
          .collection('logs')
          .where('timestamp', '>', dateSinceTimestamp);
      docRef.get().then( (queryResult) => {
        if (queryResult.size > 0) {
          var countMoods = {};

          // Sort the logs by timestamp
          var result = queryResult.docs.map(doc => doc.data());

          // Iterate through the logs; generate date for the graph
          // and for other statistics to display
          var date = null;

          var mentalStatusesByDay = {};
          var physicalStatusesByDay = {};

          var exerciseEfficacy = {};
          exerciseEfficacy[ProgramActions['breath_step']['key']] = {better: 0, notBetter: 0};
          exerciseEfficacy[ProgramActions['talk_step']['key']] = {better: 0, notBetter: 0};
          exerciseEfficacy[ProgramActions['walk_step']['key']] = {better: 0, notBetter: 0};
          exerciseEfficacy[ProgramActions['body_scan_step']['key']] = {better: 0, notBetter: 0};
          exerciseEfficacy[ProgramActions['sense_step']['key']] = {better: 0, notBetter: 0};

          // Iterate through the logs
          result.forEach( (d) => {
            date = moment(d.timestamp).format("YYYY-MM-DD");

            // Handle the case of multiple entries on a given date by averaging them
            if (mentalStatusesByDay[date]) {
              mentalStatusesByDay[date]['count'] = mentalStatusesByDay[date]['count'] + 1;
              mentalStatusesByDay[date]['total'] = mentalStatusesByDay[date]['total'] + d.mental_initial;
            }
            else {
              mentalStatusesByDay[date] = {
                count: 1,
                total: d.mental_initial
              };
            }
            if (physicalStatusesByDay[date]) {
              physicalStatusesByDay[date]['count'] = physicalStatusesByDay[date]['count'] + 1;
              physicalStatusesByDay[date]['total'] = physicalStatusesByDay[date]['total'] + d.physical_initial;
            }
            else {
              physicalStatusesByDay[date] = {
                count: 1,
                total: d.physical_initial
              };
            }

            // Sum up the different moods
            if (!countMoods[d.current_sub_feeling]) {
              countMoods[d.current_sub_feeling] = 1;
            }
            else {
              countMoods[d.current_sub_feeling] = countMoods[d.current_sub_feeling] + 1;
            }

            // Calculate exercise efficacy
            var key = d.action;
            var exerciseResult = d.exercise_result;
            if (exerciseResult > 0) {
              let currentVal = exerciseEfficacy[key]['better'];
              exerciseEfficacy[key]['better'] = currentVal + 1;
            }
            else {
              let currentVal = exerciseEfficacy[key]['notBetter'];
              exerciseEfficacy[key]['notBetter'] = currentVal + 1;
            }
          });

          // Process averages for the status charts to handle duplicates
          var newMentalStatuses = [];
          for (const key of Object.keys(mentalStatusesByDay)) {
            newMentalStatuses.push({
              x: key,
              y: parseFloat(mentalStatusesByDay[key]['total']) / mentalStatusesByDay[key]['count']
            });
          }
          var newPhysicalStatuses = [];
          for (const key of Object.keys(physicalStatusesByDay)) {
            newPhysicalStatuses.push({
              x: key,
              y: parseFloat(physicalStatusesByDay[key]['total']) / physicalStatusesByDay[key]['count']
            });
          }

          this.setState({
            logs: result,
            mindTimeSeriesOutputData: newMentalStatuses,
            bodyTimeSeriesData: newPhysicalStatuses,
            moods: countMoods,
            exerciseEfficacy: exerciseEfficacy,
          });
        } else {
          console.log("No logs found!");
        }
      }).catch( (error) => {
        console.log("Error getting document:", error);
      });
    }
  }

  render() {
    // Mind / Body status descriptions
    const activityDesc = ProgramActions[this.props.action].description;
    const activityTitle = ProgramActions[this.props.action].humanReadable;
    var improvedText = `No improvement after ${activityDesc}`;
    if (this.props.feelingBetter === 1) {
      improvedText = `Feeling better after ${activityDesc}`
    }

    const loggedInButtonContent = (
      (this.state.isSaving) ? null :
      <button type="submit" className="SelectionButton track-button" onClick={this.saveValues}>
        <FontAwesomeIcon icon={faChartBar} />
        &nbsp;
        TRACK SESSION
      </button>
    );
    const notLoggedInButtonContent = (
      <Link className="SelectionButton track-button" to={{
        pathname: '/login',
        state: this.getValues()
      }}>
        <FontAwesomeIcon icon={faChartBar} />
        &nbsp;
        TRACK SESSION
      </Link>
    );

    const trackButton = (
      (!this.props.authUser) ? notLoggedInButtonContent : loggedInButtonContent
    );

    const currentDate = moment().format('L');

    var signoutButton = null;
    if (!!this.props.authUser) {
      signoutButton = (<div className="signout-button">
                        <label onClick={this.signout}>Sign Out</label>
                      </div>);
    }

    return (
      <div className={`box-containing-step ${this.props.skipped ? 'hundred-height' : null}`}>
        <div>
          <div className="flex-container">
            <div className="conclusion-announce flexible-width conclusion-box">
              <p className="conclusion-date">{currentDate}</p>
              <p className="conclusion-subheader">SELECTED EXERCISE</p>
              <hr className="header-divider" />
              <p className="conclusion-header">{activityTitle}</p>
              <hr className="header-divider" />
              <div className="flex-container">
                <div className="status-text-holder">
                  <label className="status-text">
                    <FontAwesomeIcon className="status-label-icon" icon={faClock} />
                    &nbsp;
                    {this.startTimeText}
                  </label>
                  <label className="status-text">
                    <FontAwesomeIcon className="status-label-icon" icon={faStopwatch} />
                    &nbsp;
                    {this.durationText}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CurrentStatusArea
          subFeeling1={this.props.subFeeling1}
          improvedText={improvedText}
          howFeelingMentallyInitial={this.props.howFeelingMentallyInitial}
          howFeelingPhysicallyInitial={this.props.howFeelingPhysicallyInitial}
          trackButton={trackButton}
          skipped={this.props.skipped}
        />
        <div>
          <div className="flex-container">
            <div className="fade-in flexible-width contact-area conclusion-box">
              <p className="feedback-description">We would love your feedback!</p>
              <p className="feedback-email">
                <a className="link" href="mailto:info@getserenity.app">
                  info@getserenity.app
                </a>
              </p>
            </div>
          </div>
        </div>
        {signoutButton}
      </div>
    );
  }
}

export default withFirebase(withRouter(ConclusionStep));
