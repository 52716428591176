import React, { Component } from 'react';
import {Line} from 'react-chartjs-2';
import moment from 'moment';
import StatusStringMap from '../../models/status_string_map.js';

class StatusBarChart extends Component {
  render() {
    const data = {
      datasets: [
        {
          label: 'Mind',
          backgroundColor: '#44FF0055',
          borderColor: '#44FF00',
          data: this.props.mindTimeSeriesData,
          lineTension: 0,
        },
        {
          label: 'Body',
          backgroundColor: '#64c9fc88',
          borderColor: '#64c9fc',
          data: this.props.bodyTimeSeriesData,
          lineTension: 0,
        }
      ]
    };
    const options = {
      responsive: true,
      legend: {
        labels: {
          fontColor: '#ffffff'
        }
      },
      tooltips: {
        enabled: false
      },
      scales: {
        xAxes: [{
          type: 'time',
          distribution: 'linear',
          gridLines: {
            drawOnChartArea: false,
            color: '#ffffff'
          },
          ticks: {
            suggestedMin: moment(new Date()).subtract(60, 'days').format("YYYY-MM-DD"),
            suggestedMax: moment(new Date()).add(2, 'days').format("YYYY-MM-DD"),
            fontColor: '#ffffff'
          },
          time: {
            unit: 'day'
          }
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true,
            min: 1,
            max: 7,
            fontColor: '#ffffff',
            callback: (value, index, values) => {
              return StatusStringMap[value];
            }
          },
          gridLines: {
            drawOnChartArea: false,
            color: '#ffffff'
          }
        }]
      }
    }

    return (
      <div className="status-bar-chart">
        <Line data={data} options={options} />
      </div>
    );
  }
}

export default StatusBarChart;
