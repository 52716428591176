import React, { Component } from 'react';

class WalkStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false
    };
    this.timeoutHolder = null;
  }

  keypressDetection = (event) => {
    // Enter
    if (event.keyCode === 13) {
      this.props.advanceWizard();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keypressDetection, false);
    this.timeoutHolder = setTimeout(() => {
      this.setState({
        ready: true
      });
    }, 1000 * 30);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keypressDetection, false);
    if (this.timeoutHolder) {
      clearTimeout(this.timeoutHolder);
    }
  }

  render() {
    var buttonContent = null;
    if (this.state.ready) {
      buttonContent = (
        <button className="SelectionButton" type="button" onClick={this.props.advanceWizard}>
          Continue
        </button>
      );
    }
    return(
      <header className="App-header">
        <div className="fade-in">
          <p className="fixed-paragraph">
            Take a 5 minute walk, and continue when you're back.
          </p>
          {buttonContent}
        </div>
      </header>
    );
  }
}

export default WalkStep;
