import React, { Component } from 'react';
import { withFirebase } from './components/Firebase';

// Import the steps
import InitialStep from './steps/InitialStep.js';
import HowFeelingMentallyInitialStep from './steps/HowFeelingMentallyInitialStep.js';
import HowFeelingPhysicallyInitialStep from './steps/HowFeelingPhysicallyInitialStep.js';
import SelectProgramStep from './steps/SelectProgramStep.js';
import BreathStep from './steps/BreathStep.js';
import BodyScanStep from './steps/BodyScanStep.js';
import WalkStep from './steps/WalkStep.js';
import TalkStep from './steps/TalkStep.js';
import PostTalkStep from './steps/PostTalkStep.js';
import ConclusionStep from './steps/ConclusionStep.js';
import CurrentFeelingStep from './steps/CurrentFeelingStep.js';
import FeelingBetterStep from './steps/FeelingBetterStep.js';
import SenseScanStep from './steps/SenseScanStep.js';
import ResetButton from './components/elements/ResetButton';
import ReactGA from 'react-ga';

import './SerenityApp.scss';

const INITIAL_STEP_KEY = 'initial';
const HOW_FEELING_MENTALLY_INITIAL_STEP_KEY = 'how_feeling_mentally_initial';
const HOW_FEELING_PHYSICALLY_INITIAL_STEP_KEY = 'how_feeling_physically_initial';
const SELECT_PROGRAM_STEP_KEY = 'select_program';
const BREATH_STEP_KEY = 'breath_step';
const BODY_SCAN_STEP_KEY = 'body_scan_step'
const WALK_STEP_KEY = 'walk_step';
const TALK_STEP_KEY = 'talk_step';
const CONCLUSION_STEP_KEY = 'conclusion_step';
const POST_TALK_STEP_KEY = 'post_talk_step';
const CURRENT_FEELING_STEP_KEY = 'current_feeling_step';
const FEELING_BETTER_STEP_KEY = 'feeling_better_step';
const SENSE_SCAN_STEP_KEY = 'sense_step';

const INITIAL_STATE = {
  step: INITIAL_STEP_KEY,
  howFeelingMentallyInitial: null,
  howFeelingPhysicallyInitial: null,
  baseFeeling: null,
  subFeeling1: null,
  skipped: false,
  action: null,
  talkStepOutput: "",
  startTime: 0,
  feelingBetter: 0,
  comingFromLogin: false
};

// Use Google Analytics if not in development mode
if (process.env.NODE_ENV !== 'development') {
  ReactGA.initialize('UA-156984354-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

class WizardStepHolder extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(
      (authUser) => {
        // These parameters are passed in by the router that navigated us here. If
        // we are running our post-signup flow (ie the user signed up and then was
        // navigated here, then savedStateToBePersisted will not be null)
        if (this.props.location.state && this.props.location.state.savedStateToBePersisted) {
          const savedState = this.props.location.state.savedStateToBePersisted;

          // Set our state appropriately for the navigation
          // And navigate us to the Conclusion Step
          this.setState({
            howFeelingMentallyInitial: savedState.mental_initial,
            howFeelingPhysicallyInitial: savedState.physical_initial,
            baseFeeling: savedState.current_base_feeling,
            subFeeling1: savedState.current_sub_feeling,
            exerciseResult: savedState.exercise_result,
            action: savedState.action,
            startTime: savedState.timestamp,
            skipped: false,
            comingFromLogin: true,
            step: CONCLUSION_STEP_KEY
          });
        }
      }
    );
  }

  componentWillUnmount() {
    this.listener();
  }

  doReset = () => {
    this.setState(INITIAL_STATE);
  }

  setHowFeelingMentallyInitial = (val) => {
    this.setState({
      howFeelingMentallyInitial: val
    });
  }

  setHowFeelingPhysicallyInitial = (val) => {
    this.setState({
      howFeelingPhysicallyInitial: val
    });
  }

  setHowFeelingMentallyFinal = (val) => {
    this.setState({
      howFeelingMentallyFinal: val
    });
  }

  setHowFeelingPhysicallyFinal = (val) => {
    this.setState({
      howFeelingPhysicallyFinal: val
    });
  }

  setBaseFeeling = (val) => {
    this.setState({
      baseFeeling: val
    });
  }

  setSubFeeling1 = (val) => {
    this.setState({
      subFeeling1: val
    });
  }

  initializeTime = () => {
    this.setState({
      startTime: Date.now()
    });
  }

  selectNextStep = (value) => {
    this.setState({
      step: value
    });
  }

  setFeelingBetter = (value) => {
    this.setState({
      feelingBetter: value
    });
  }

  handleSkip = () => {
    this.setState({
      step: SELECT_PROGRAM_STEP_KEY,
      skipped: true
    });
  }

  render() {
    var currentStep = null;
    var showReset = true;

    if (this.state.step === INITIAL_STEP_KEY) {
      currentStep = (
        <InitialStep
          advanceWizard={ () => this.setState({step: HOW_FEELING_PHYSICALLY_INITIAL_STEP_KEY}) }
          initializeTime={this.initializeTime}
          goWizard={ () => this.setState({step: CONCLUSION_STEP_KEY}) }
        />
      );
      showReset = false;
    }
    else if (this.state.step === HOW_FEELING_PHYSICALLY_INITIAL_STEP_KEY) {
      currentStep = (
        <HowFeelingPhysicallyInitialStep
          setHowFeelingPhysicallyInitial={this.setHowFeelingPhysicallyInitial}
          advanceWizard={ () => this.setState({step: HOW_FEELING_MENTALLY_INITIAL_STEP_KEY}) }
          handleSkip={this.handleSkip}
        />
      );
    }
    else if (this.state.step === HOW_FEELING_MENTALLY_INITIAL_STEP_KEY) {
      currentStep = (
        <HowFeelingMentallyInitialStep
          setHowFeelingMentallyInitial={this.setHowFeelingMentallyInitial}
          advanceWizard={ () => this.setState({step: CURRENT_FEELING_STEP_KEY}) }
          handleSkip={this.handleSkip}
        />
      );
    }
    else if (this.state.step === CURRENT_FEELING_STEP_KEY) {
      currentStep = (
        <CurrentFeelingStep
          advanceWizard={ () => this.setState({step: SELECT_PROGRAM_STEP_KEY}) }
          setBaseFeeling={this.setBaseFeeling}
          setSubFeeling1={this.setSubFeeling1}
          handleSkip={this.handleSkip}
        />
      );
    }
    else if (this.state.step === SELECT_PROGRAM_STEP_KEY) {
      currentStep = (
        <SelectProgramStep
          selectNextStep={this.selectNextStep}
          advanceWizard={ () => this.setState({step: SELECT_PROGRAM_STEP_KEY}) }
          howFeelingMentallyInitial={this.state.howFeelingMentallyInitial}
          howFeelingPhysicallyInitial={this.state.howFeelingPhysicallyInitial}
          subFeeling1={this.state.subFeeling1}
          skipped={this.state.skipped}
        />
      );
    }
    else if (this.state.step === BREATH_STEP_KEY) {
      currentStep = (
        <BreathStep
          advanceWizard={ () => {
            this.setState({
              step: FEELING_BETTER_STEP_KEY,
              action: BREATH_STEP_KEY
            });
          } }
        />
      );
    }
    else if (this.state.step === BODY_SCAN_STEP_KEY) {
      currentStep = (
        <BodyScanStep
          advanceWizard={ () => {
            this.setState({
              step: FEELING_BETTER_STEP_KEY,
              action: BODY_SCAN_STEP_KEY
            });
          } }
        />
      );
    }
    else if (this.state.step === WALK_STEP_KEY) {
      currentStep = (
        <WalkStep
          advanceWizard={ () => {
            this.setState({
              step: FEELING_BETTER_STEP_KEY,
              action: WALK_STEP_KEY
            });
          } }
        />
      );
    }
    else if (this.state.step === TALK_STEP_KEY) {
      currentStep = (
        <TalkStep
          advanceWizard={ () => {
            this.setState({
              step: POST_TALK_STEP_KEY,
              action: TALK_STEP_KEY
            });
          } }
          setTalkOutput={ (value) => this.setState({talkStepOutput: value}) }
        />
      );
    }
    else if (this.state.step === POST_TALK_STEP_KEY) {
      currentStep = (
        <PostTalkStep
          advanceWizard={ () => this.setState({step: FEELING_BETTER_STEP_KEY}) }
          talkStepOutput={ this.state.talkStepOutput }
        />
      );
    }
    else if (this.state.step === SENSE_SCAN_STEP_KEY) {
      currentStep = (
        <SenseScanStep
          advanceWizard={ () => {
            this.setState({
              step: FEELING_BETTER_STEP_KEY,
              action: SENSE_SCAN_STEP_KEY
            });
          } }
        />
      );
    }
    else if (this.state.step === FEELING_BETTER_STEP_KEY) {
      currentStep = (
        <FeelingBetterStep
          advanceWizard={ () => this.setState({step: CONCLUSION_STEP_KEY}) }
          setFeelingBetter={ this.setFeelingBetter }
        />
      );
    }
    else if (this.state.step === CONCLUSION_STEP_KEY) {
      currentStep = (
        <ConclusionStep
          authUser={this.props.authUser}
          howFeelingMentallyInitial={this.state.howFeelingMentallyInitial}
          howFeelingPhysicallyInitial={this.state.howFeelingPhysicallyInitial}
          baseFeeling={this.state.baseFeeling}
          subFeeling1={this.state.subFeeling1}
          action={this.state.action}
          startTime={this.state.startTime}
          feelingBetter={this.state.feelingBetter}
          doReset={this.doReset}
          firebase={this.props.firebase}
          skipped={this.state.skipped}
          comingFromLogin={this.state.comingFromLogin}
        />
      );
      showReset = false;
    }

    return (
      <div style={{height: '100%'}}>
        {currentStep}
        <ResetButton doReset={this.doReset} showReset={showReset} />
      </div>
    );
  }
}

export default withFirebase(WizardStepHolder);
