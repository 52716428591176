import React, { Component } from 'react';
import StatusSelector from '../components/StatusSelectors/StatusSelector';
import SkipLink from '../components/elements/SkipLink.js';

class HowFeelingMentallyInitialStep extends Component {
  handleValueSelection = (value) => {
    this.props.setHowFeelingMentallyInitial(value);
    this.props.advanceWizard();
  }

  render() {
    return (
      <header className="App-header">
        <div className="fade-in">
          <p className="gutter-bottom">How is your mind feeling?</p>
          <div>
            <StatusSelector handleValueSelection={this.handleValueSelection} />
          </div>
          <SkipLink handleSkip={this.props.handleSkip} />
        </div>
      </header>
    );
  }
}

export default HowFeelingMentallyInitialStep;
