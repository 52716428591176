import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowLeft, faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons';

class SenseScanStep extends Component {
  constructor(props) {
    super(props);

    var SIGHT_OPTIONS = [
      (
        <div>
          <FontAwesomeIcon className="status-label-icon" icon={faArrowLeft} /> Look left
          <div>
            <button className="SelectionButton" type="button" onClick={this.nextItem}>
              Done
            </button>
          </div>
        </div>
      ),
      (
        <div>
          <FontAwesomeIcon className="status-label-icon" icon={faArrowRight} /> Look Right
          <div>
            <button className="SelectionButton" type="button" onClick={this.nextItem}>
              Done
            </button>
          </div>
        </div>
      ),
      (
        <div>
          <FontAwesomeIcon className="status-label-icon" icon={faArrowUp} /> Look up
          <div>
            <button className="SelectionButton" type="button" onClick={this.nextItem}>
              Done
            </button>
          </div>
        </div>
      ),
      (
        <div>
          <FontAwesomeIcon className="status-label-icon" icon={faArrowDown} /> Look down
          <div>
            <button className="SelectionButton" type="button" onClick={this.nextItem}>
              Done
            </button>
          </div>
        </div>
      ),
    ];

    var senseSteps = [
      (
        <div>
          <p>
            At each step, follow the instructions and take a deep breath
          </p>
          <div>
            <button className="SelectionButton" type="button" onClick={this.nextItem}>
              Start
            </button>
          </div>
        </div>
      )
    ];

    senseSteps = senseSteps.concat(this.shuffle(SIGHT_OPTIONS));

    senseSteps.push(
      <div>
        Look straight ahead
        <div>
          <button className="SelectionButton" type="button" onClick={this.nextItem}>
            Done
          </button>
        </div>
      </div>
    );
    senseSteps.push(
      <div>
        Listen to sounds to your left
        <div>
          <button className="SelectionButton" type="button" onClick={this.nextItem}>
            Done
          </button>
        </div>
      </div>
    );
    senseSteps.push(
      <div>
        Listen to sounds to your right
        <div>
          <button className="SelectionButton" type="button" onClick={this.nextItem}>
            Done
          </button>
        </div>
      </div>
    );
    senseSteps.push(
      <div>
        Take a deep breath
        <div>
          <button className="SelectionButton" type="button" onClick={this.doAdvance}>
            Continue
          </button>
        </div>
      </div>
    );

    this.senseSteps = senseSteps;

    this.state = {
      itemIndex: 0
    };
  }

  keypressDetection = (event) => {
    // Enter
    if (event.keyCode === 13) {
      if (this.state.itemIndex === (this.senseSteps.length - 1)) {
        this.doAdvance();
      }
      else {
        this.nextItem();
      }
    }
    // Escape
    else if (event.keyCode === 27) {
      this.doAdvance();
    }
  }

  doAdvance = (event) => {
    this.props.advanceWizard();
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keypressDetection, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keypressDetection, false);
  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  nextItem = () => {
    var nextIndex = this.state.itemIndex + 1;
    this.setState({
      itemIndex: nextIndex
    })
  }

  render() {
    var currentSenseCheckContent = this.senseSteps[this.state.itemIndex];

    return(
      <header className="App-header">
        <div className="fade-in">
          {currentSenseCheckContent}
        </div>
      </header>
    );
  }
}

export default SenseScanStep;
