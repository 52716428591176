import React, { Component } from 'react';

const PARTS = [
  'toes', 'calves', 'thighs', 'lower torso', 'stomach', 'shoulders', 'chest', 'hands', 'arms', 'neck', 'head'
];
const TIME_PER_SCAN = 9 * 1000;

class BodyScanStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: null,
      currentPart: null
    };
    this.intervalReference = null;
  }

  keypressDetection = (event) => {
    // Enter
    if (event.keyCode === 13) {
      if (this.state.currentPart == null) {
        this.startBodyScan();
      }
      else if (this.state.done) {
        this.props.advanceWizard();
      }
    }
    // Esc
    else if (event.keyCode === 27) {
      if (this.state.currentPart != null && !this.state.done) {
        if (this.intervalReference) {
          window.clearInterval(this.intervalReference);
        }
        this.setState({
          done: true
        })
      }
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keypressDetection, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keypressDetection, false);
  }

  startBodyScan = () => {
    var parts = [...PARTS];
    this.setState({
      currentPart: parts.shift()
    });

    var timer = window.setInterval( () => {
      if (parts.length > 0) {
        var part = parts.shift();
        this.setState({
          currentPart: part
        });
      }
      else {
        window.clearInterval(timer);
        this.setState({
          done: true
        });
      }
    }, TIME_PER_SCAN);
    this.intervalReference = timer;
  }

  render() {
    var nextActionButton = null;
    if (this.state.done) {
      nextActionButton = (
        <button className="SelectionButton" type="button" onClick={this.props.advanceWizard}>
          Next
        </button>
      );
    }
    else if (this.state.currentPart == null) {
      nextActionButton = (
        <button className="SelectionButton" type="button" onClick={this.startBodyScan}>
          Start
        </button>
      );
    }

    var instructionText = (
      <div>
        <p className="small-text fixed-paragraph">Press Enter to start, Esc to cancel</p>
      </div>
    );
    if (this.state.done) {
      instructionText =(
        <div className="fade-in">
          <p className="small-text">
            Take a deep breath.
          </p>
        </div>
      )
    }
    else if (this.state.currentPart != null) {
      instructionText = (
        <div className="fade-in">
          <p className="small-text">Relax your <span className="fade-in current-body-scan-part" key={this.state.currentPart}>{this.state.currentPart}...</span></p>
        </div>
      )
    }

    return (
      <header className="App-header">
        <div className="fade-in">
          <div>
            {instructionText}
          </div>
          <div>
            {nextActionButton}
          </div>
        </div>
      </header>
    );
  }
}

export default BodyScanStep;
