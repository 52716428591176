import React, { Component } from 'react';

class PostTalkStep extends Component {
  keypressDetection = (event) => {
    // Enter
    if (event.keyCode === 13) {
      this.props.advanceWizard();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keypressDetection, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keypressDetection, false);
  }
  
  render() {
    return (
      <header className="App-header">
        <div className="fade-in">
          <p className="small-text">Pause and reflect...</p>
          <p className="talk-it-out-result">
            {this.props.talkStepOutput}
          </p>
          <div>
            <button className="SelectionButton" type="button" onClick={this.props.advanceWizard}>
              Done
            </button>
          </div>
        </div>
      </header>
    );
  }
}

export default PostTalkStep;
