import React, { Component } from 'react';
import './App.scss';
import { BrowserRouter as Router, Route } from "react-router-dom";
import SignupPage from './pages/Signup.js';
import SigninPage from './pages/Signin.js';
import ProfilePage from './pages/Profile.js';
import { withFirebase } from './components/Firebase';
import WizardStepHolder from './WizardStepHolder.js'

// Create App Component that frames the page
// Create specific sub-Component that actually contains the Wizard experience, with conditionals

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authUser: null,
    };
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(
      authUser => {
        authUser ? this.setState({ authUser }) : this.setState({ authUser: null });
      });
  }

  componentWillUnmount() {
    this.listener();
  }

  render() {
    return (
      <div className="App">
        <Router>
          <Route exact path="/" render={(props) => <WizardStepHolder {...props} authUser={this.state.authUser} /> }></Route>
          <Route exact path="/profile" render={(props) => <ProfilePage {...props} authUser={this.state.authUser} /> }></Route>
          <Route exact path="/signup" component={SignupPage}></Route>
          <Route exact path="/login" component={SigninPage}></Route>
        </Router>
      </div>
    );
  }
}

export default withFirebase(App);
