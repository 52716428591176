import React, { Component } from 'react';

class InitialStep extends Component {
  keypressDetection = (event) => {
    // Enter
    if (event.keyCode === 13) {
      this.startSession();
    }
    // g
    if (process.env.NODE_ENV === 'development' && event.keyCode === 71) {
      this.props.goWizard();
    }
  }

  startSession = () => {
    this.props.initializeTime();
    this.props.advanceWizard();
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keypressDetection, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keypressDetection, false);
  }

  render() {
    return(
      <header className="App-header">
        <div>
          <p>
            Find a calm, relaxed place.
          </p>
          <button
            type="submit"
            className="SelectionButton"
            onClick={this.startSession}
          >
            Start
          </button>
        </div>
      </header>
    );
  }
}

export default InitialStep;
